import { Link } from "gatsby";
import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";
export default function Press() {
  const data = [
    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/press/hindustan-times-15-09-2024.jpg",
      title:
        "Hindustan Times - Principal of Chinmaya Vidyalaya conferred with prestigious award - 15th September 2024",
      link: "https://epaper.hindustantimes.com/delhi?eddate=15/09/2024&pageid=782537",
    },
    {
      img: "https://ht-mint-epaper-fs.s3.ap-south-1.amazonaws.com/HT/2024/09/01/HTC_DELH/HTC_DELH/5_03/e2349c_776947_1_mr.jpg",
      title:
        "Hindustan Times - Students get traditional wisdom with modern pedagogy",
      link: "https://epaper.hindustantimes.com/Home/ShareArticle?OrgId=1901e45ee8&imageview=0",
    },
    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/press/htimes-clipping-23.png",
      title:
        "Hindustan Times - Excellence is not limited to academics at Chinmaya Vidyalaya",
      link: "https://epaper.hindustantimes.com/Home/ShareArticle?OrgId=24116ea1e6f9&imageview=0",
    },
    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/press/times-school-survey-2023.jpg",
      title:
        "Chinmaya Vidyalaya, Vasant Vihar has been adjudged the 4th best school in South-West Delhi district by Times School Survey, 2023.",
      link: "",
    },
    {
      img: "https://chinmayavvdelhi.ac.in/assets/images/press/ht-city-290523.jpeg",
      title:
        "Chinmaya Vidyalaya Delhi in the limelight in today's HT City section.",
      link: "https://www.hindustantimes.com/brand-stories/skill-development-entrepreneurship-to-get-a-fillip-at-chinmaya-vidyalaya-vasant-vihar-101685030774638.html",
    },
    {
      link: "https://www.hindustantimes.com/brand-stories/the-true-essence-of-learning-is-in-our-roots-101673616738335.html",
      img: "https://chinmayavvdelhi.ac.in/assets/images/press/hindustan-times-15-01-2023.jpeg",
      title: "Hindustan Times, 15th January, 2023",
    },
    {
      link: "",
      img: "https://chinmayavvdelhi.ac.in/assets/images/press/time-of-india.jpeg",
      title: "Times of India, 27th September, 2022",
    },
  ];

  const cards = data.map((item) => (
    <a href={item.link} target="_blank" className="press__cards__card">
      <div className="press__cards__card__title">{item.title}</div>
      <img className="press__cards__card__img" src={item.img}></img>
    </a>
  ));
  return (
    <Layout>
      <SEO
        title="Press | Chinmaya Vidyalaya"
        description=""
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="press">
        <h1 className="heading">Press</h1>

        <div className="press__cards">{cards}</div>
      </div>
    </Layout>
  );
}
